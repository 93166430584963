import React from 'react';
import './sticky.css';

class StickyContent extends React.Component {
  render() {
    const { children, modifiers } = this.props;

    const modifierClasses = modifiers.length ? modifiers.reduce((result, modifier) => {
      result = `${result} ${StickyContent.baseClass}--${modifier}`;
      return result;
    }, '') : '';
    return (
        <div className={`${StickyContent.baseClass} ${modifierClasses}`}>
          {children}
         {Boolean(modifierClasses && this.props.title) &&
         ''
         }
        </div>
    );
  }
}

StickyContent.baseClass = 'StickyContent';

export default StickyContent;
