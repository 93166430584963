import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link, StaticQuery} from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Layout from "../components/Layout";
import Sticky from "../components/Sticky";
import StickyContent from "../components/StickyContent";
import {v4} from 'uuid'

const sides = {
  top: 0, // Sticks when it scrolls past the top edge
  // bottom: 0, // Sticks when it scrolls past the bottom edge
  // left: 10, // Sticks 10px from the left edge
  // right: -20, // Sticks 20px past the right edge (useful for content that should stick only when it's fully out of the frame)
};

class PricePage extends React.Component {
  constructor(props) {
    super(props);
    this.anchors = {}
  }

  scrollTo = id => {
    const element = this.anchors[id]
    element.scrollIntoView({behavior: 'smooth', block: 'center'})
  }

  sortBySeason = (prices) => {
    if (!Array.isArray(prices)) return [];
    return prices.sort((a, b) => {
      const startA = new Date(a.start);
      const startB = new Date(b.start);
      return startA - startB;
    })
  }

  renderVehicleInfo = (frontmatter, id, fields, lastVehicleIndex, index) => {
    const sortedPrices = this.sortBySeason(frontmatter.preise)
    const {location} = this.props;
    const reg = new RegExp("/en")
    const en = reg.test(location.pathname);
    return (
        <React.Fragment key={id}>
          <div className="column is-12">
            <div className="columns" style={{margin: '8px 0'}}>
              <div className={'column is-4 is-offset-1'}>
                <Link to={fields.slug}>
                  <div className="vehicle-card card ">
                    <div className="card-image">
                      <figure className="image is-2by1 is-marginless is-paddingless">
                        {(frontmatter.images && frontmatter.images[0]) &&
                        <PreviewCompatibleImage imageInfo={frontmatter.thumbnail || frontmatter.images[0]}/>
                        }
                      </figure>
                    </div>
                    <div className="card-content">
                      <div className="content">
                        {frontmatter.title}
                      </div>
                    </div>
                  </div>
                </Link>
                <button className={'button is-rounded blueborder'} style={{marginTop: 16}}>
                  <a href={'/contact'} className='is-blue'>
                    {en ? 'Booking' : 'Buchungsanfrage'}
                  </a>
                </button>
              </div>
              <div className={'column is-7'}>
                <table className="table">
                  <tbody>
                  {sortedPrices.map((price, i) =>
                      <tr key={i}>
                        <td>
                          <strong className='is-dark-snd'>
                            {en ? (price.name_en || 'common season') : price.name}
                          </strong>
                          <div className="">
                            {new Date(price.start).toLocaleDateString()} - {new Date(price.end).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="is-flex is-centered">{price.price} €</td>
                      </tr>
                  )}
                  </tbody>
                </table>
              </div>
              {index !== lastVehicleIndex && <div className="has-border-bottom" style={{width: '100%'}}/>}
            </div>
          </div>
        </React.Fragment>
    )
  }

  renderSubGroup = (vehicles, groupName) => {
    return vehicles.edges
        .filter(({node: {frontmatter}}) =>
            frontmatter.category.toLowerCase() === groupName.toLowerCase())
        .map(({node: {frontmatter, id, fields}}, i) =>
            this.renderVehicleInfo(frontmatter, id, fields, vehicles.edges.length - 1, i))
  }

  renderInfo = () => {
    const {categories, vehicles, prices} = this.props.data;
    const pricePage = prices.edges[0].node.frontmatter
    const {location} = this.props;
    const reg = new RegExp("/en")
    const en = reg.test(location.pathname);
    return (
        <section className="section" key={v4()}>

          <div className="columns is-multiline is-vcentered has-padding-bottom has-side-padding" ref={ref => {
            this.anchors['info'] = ref
          }}>
            <div className={'column is-10 is-offset-1'}>
              <div className="columns is-multiline is-vcentered has-border-bottom has-padding-bottom is-between">
                <h3 className={'title is-dark-snd is-inline-block '}>{`${en ? pricePage.list_title_en : pricePage.list_title}`}</h3>
              </div>
            </div>
          </div>
          <div className="columns is-multiline has-padding-bottom has-side-padding has-border-bottom">
            <div className="column is-10 is-offset-1 has-border-bottom">
              <div className="subtitle is-dark-snd"
                   style={{marginTop: 18}}>{en ? 'Options & Conditions' : 'Zuschläge und Optionen'}</div>
              <ul className="has-text-grey-dark has-text-weight-light is-raleway" style={{marginBottom: 24}}>
                {pricePage.options.map(({option, option_en}) =>
                    <li key={option} className="is-vcentered ">
                      {en ? option_en : option}
                    </li>
                )}
              </ul>
            </div>
            <div className="column is-5 is-offset-1">
              <div className="subtitle is-dark-snd"
                   style={{marginTop: 18}}>{en ? "Prices include" : "Mietpreise schließen ein"}</div>
              <ul className="has-text-grey-dark has-text-weight-light is-raleway" style={{marginBottom: 24}}>
                {pricePage.prices_include.map(({option, option_en}) =>
                    <li key={option} className="is-vcentered ">
                      <span className="icon has-text-success is-medium">
                        <i className="fas fa-check-square fa-lg"/>
                      </span>
                      {en ? option_en : option}
                    </li>
                )}
              </ul>
            </div>

            <div className="column is-5">
              <div className="subtitle is-dark-snd" style={{marginTop: 18}}>
                {en ? 'Kilometers included' : 'Kilometerbeschränkungen'}
              </div>
              <ul className="has-text-grey-dark has-text-weight-light is-raleway" style={{marginBottom: 24}}>
                {pricePage.km_include.map(({option, option_en}) =>
                    <li key={option} className="is-vcentered ">
                      <span className="icon has-text-success is-medium">
                        <i className="fas fa-check-square fa-lg"/>
                      </span>
                      {en ? option_en : option}
                    </li>
                )}
              </ul>
            </div>

            <div className="column is-5 is-offset-1">
              <div className="subtitle is-dark-snd" style={{marginTop: 18}}>
                {en ? 'Service fee includes' : 'Mietpauschale schließt ein'}
              </div>
              <ul className="has-text-grey-dark has-text-weight-light is-raleway" style={{marginBottom: 24}}>
                {pricePage.allowance_includes.map(({option, option_en}) =>
                    <li key={option} className="is-vcentered ">
                      <span className="icon has-text-success is-medium">
                        <i className="fas fa-check-square fa-lg"/>
                      </span>
                      {en ? option_en : option}
                    </li>
                )}
              </ul>
            </div>


            <div className="column is-5">
              <div className="subtitle is-dark-snd" style={{marginTop: 18}}>
                {en ? 'Your advantages' : 'Unsere Pluspunkte für Sie'}
              </div>
              <ul className="has-text-grey-dark has-text-weight-light is-raleway">
                {pricePage.plus_points.map(({option, option_en}) =>
                    <li key={option} className="is-vcentered ">
                      <span className="icon has-text-success is-medium">
                        <i className="fas fa-check-square fa-lg"/>
                      </span>
                      <div className="is-inline">
                        {en ? option_en : option}
                      </div>
                    </li>
                )}
              </ul>
            </div>
          </div>

        </section>
    )
  }

  renderCategories = () => {
    const {categories, vehicles} = this.props.data;
    const {location} = this.props;
    const reg = new RegExp("/en")
    const en = reg.test(location.pathname);
    return categories.edges.map(({node: {frontmatter: {title}, id: titleID}}) =>
        <React.Fragment key={titleID}>
          <div className="columns is-multiline is-vcentered has-padding-bottom has-side-padding"
               style={{marginTop: 16}}
               ref={ref => {
                 this.anchors[title] = ref
               }}>
            <div className={'column is-10 is-offset-1'}>
              <div className="columns is-multiline is-vcentered has-border-bottom has-padding-bottom is-between">
                <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{`${en ? 'Our' : 'Unsere'} ${title}`}</h3>
              </div>
            </div>
          </div>
          <div className="columns is-multiline">
            {this.renderSubGroup(vehicles, title)}
          </div>
        </React.Fragment>
    )
  }

  render() {
    const {data, location} = this.props
    const {categories: {edges: vehicleCategories}, prices} = data
    const pricePage = prices.edges[0].node.frontmatter
    const reg = new RegExp("/en")
    const en = reg.test(location.pathname);
    console.log("hi")
    return (
        <Layout location={location}>
          <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url('/img/palmen-bg-dark.jpg')`,
                marginTop: 0,
                marginBottom: 0
              }}
          >
            <section className="hero is-flex" style={{flex: 1}}>
              <div className="hero-body">
                <div className="container">
                  <div className="column is-6 is-offset-1">
                    <h1 className="title" style={{color: 'white'}}>
                      {en ? pricePage.title_en : pricePage.title}

                    </h1>
                  </div>
                  <div className="column is-8 is-offset-1">
                    <h2 className="subtitle" style={{color: 'white'}}>
                      {en ? pricePage.subtitle_en : pricePage.subtitle}
                    </h2>
                  </div>
                </div>
              </div>
            </section>

          </div>
          <Sticky sides={sides}>
            <StickyContent>
              <section className="section is-paddingless">
                <div className="container">
                  <div className={'columns is-vcentered is-marginless'}>
                    <div className="column is-10 is-paddingless is-offset-1">
                      {vehicleCategories.map(({node: {frontmatter, id}}) =>
                          <button
                              className={'button is-rounded bluebg'}
                              style={{marginRight: 8}}
                              onClick={() => this.scrollTo(frontmatter.title)}
                              key={id}>
                            {frontmatter.title}
                          </button>
                      )}
                      <button className={'button is-rounded blueborder'} onClick={() => this.scrollTo('info')}>
                        {!en ? 'Informationen' : 'Information'}
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </StickyContent>
          </Sticky>
          <section className="section">
            <div className="container">
              <div className="content">
                {this.renderCategories()}
                {this.renderInfo()}
              </div>
            </div>
          </section>
        </Layout>
    )
  }
}

PricePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const query = graphql`
    query PricesQuery {
        vehicles: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "vehicle-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        category
                        thumbnail {
                              childImageSharp {
                              fluid(maxWidth: 2048, quality: 100) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                        }
                        teaser_en
                        detail_header
                        detail_header_en
                        details {
                            detail
                            detail_name_de
                            detail_value_de
                        }
                        komfort_header
                        komfort_header_en
                        komfort {
                            komfort_en
                        }
                        preise {
                            start
                            end
                            price
                            name
                            name_en
                        }
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        images {
                            title
                            alt
                            image {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 526, quality: 92) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        categories: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "categories" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    frontmatter {
                        title
                    }
                }
            }
        }
        prices: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "price-page" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 400)
                    id
                    frontmatter {
                        title
                        title_en
                        subtitle
                        subtitle_en
                        list_title
                        list_title_en
                        options {
                            option
                            option_en
                        }
                        prices_include {
                            option
                            option_en
                        }
                        km_include {
                            option
                            option_en
                        }
                        allowance_includes {
                            option
                            option_en
                        }
                        plus_points {
                            option
                            option_en
                        }
                    }
                }
            }
        }
    }
`
export default (props) => (
    <StaticQuery
        query={query}
        render={(data, count) => <PricePage data={data} {...props} />}
    />
)
